import { useQueryClient } from "react-query";
import { SetStateAction, useState } from "react";
import { Auth } from "aws-amplify";
import { useMemo } from "react";
import Router from "next/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faApple, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { faChevronLeft, faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { faChevronRight, faEnvelope } from "@fortawesome/pro-regular-svg-icons";
import AppleLogin from "react-apple-login";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { NextSeo } from "next-seo";
import * as Sentry from "@sentry/nextjs";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const queryClient = useQueryClient();
  const cognitoUserQueryKey = useMemo(() => ["cognito", "user"], []);
  const [forgotPass, setForgotPass] = useState(false);
  const [awaitingCode, setAwaitingCode] = useState(false);
  const [signingIn, setSigningIn] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorText, setErrorText] = useState("");
  function getUser() {
    const user = queryClient.getQueryData(cognitoUserQueryKey);
  }
  const reset = async () => {
    //Reset password using amplify
    try {
      const user = await Auth.forgotPassword(email).then((data) => {
        if (data.CodeDeliveryDetails.DeliveryMedium === "EMAIL")
          setAwaitingCode(true);
      });
    } catch (error) {
      // LimitExceededException
      let message = "";
      if (error instanceof Error) message = error.message;
      console.log(message);
      if (message === "Attempt limit exceeded, please try after some time.") {
        Sentry.captureException("User hit password reset limit: "+ email);
        setErrorText("Too many requests. Please try again later.");
        setHasError(true);
      }
    }
  };
  const loginWithApple = async () => {
    try {
      const user = await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Apple,
      });
    } catch (error) {
        if (error instanceof Error){
            Sentry.captureException("Error logging in with Apple: "+error.message)
        }
        console.log(error);
    }
  };
  const loginWithGoogle = async () => {
    try {
      const user = await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google,
      });
    } catch (error) {
        if (error instanceof Error){
            Sentry.captureException("Error logging in with Google: "+error.message)
        }
      console.log(error);
    }
  };
  const confirmReset = async () => {
    try {
      setErrorText("");
      setSigningIn(true);
      console.log("confirming...");
      console.log(email);
      console.log(code);
      console.log(password);
      console.log(confirmPassword);
      if (password.length < 8) {
        setErrorText("Password must be at least 8 characters");
        setHasError(true);
        return;
      }
      if (!password.match(/[a-z]/)) {
        setErrorText("Password must contain at least one lowercase letter");
        setHasError(true);
        return;
      }
      if (!password.match(/[A-Z]/)) {
        setErrorText("Password must contain at least one uppercase letter");
        setHasError(true);
        return;
      }
      if (!password.match(/[0-9]/)) {
        setErrorText("Password must contain at least one number");
        setHasError(true);
        return;
      }
      if (!password.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/)) {
        setErrorText("Password must contain at least one special character");
        setHasError(true);
        return;
      }
      if (password !== confirmPassword) {
        setHasError(true);
        setErrorText("Passwords do not match");
        return;
      }
      const resetuser = await Auth.forgotPasswordSubmit(
        email,
        code,
        password
      ).then((data) => {
        console.log("Password reset successfully");
        setAwaitingCode(false);
        setSigningIn(false);
      });
      const user = await Auth.signIn(email, password);
      queryClient.setQueryData(cognitoUserQueryKey, user);
      getUser();
        if (Router.query && Router.query?.from) {
            Router.push(Router.query.from as string);
        }
        else {
            Router.push("/");
        }
    } catch (error) {
        if (error instanceof Error){
            Sentry.captureException("User encountered error trying to reset password: "+error.message)
        }
    }
  };

  function onChange(e: {
    target: { name: any; value: SetStateAction<string> };
  }) {
    switch (e.target.name) {
      case "email":
        setEmail(e.target.value);
        break;
      case "password":
        setPassword(e.target.value);
        break;
      case "code":
        setCode(e.target.value);
        break;
      case "confirmPassword":
        setConfirmPassword(e.target.value);
        break;
      default:
        break;
    }
  }
  const login = async () => {
    try {
        setHasError(false);
        setErrorText("");
        setSigningIn(true);
        const user = await Auth.signIn(email, password);
        console.log(user);
        queryClient.setQueryData(cognitoUserQueryKey, user);
        getUser();
        console.log(Router.query.from as string)
        if (Router.query && Router.query?.from) {
            Router.push(Router.query.from as string);
        }
        else {
            Router.push("/");
        }
        } catch (error) {
        if (error == "NotAuthorizedException: Incorrect username or password.") {
            setSigningIn(false);
            setHasError(true);
            setErrorText("Incorrect username or password.");
        }
        if (error == "UserNotFoundException: User does not exist.") {
            setSigningIn(false);
            setHasError(true);
            setErrorText("User does not exist.");
        }
        if (error == "UserNotConfirmedException: User is not confirmed.") {
            if (error instanceof Error){
                Sentry.captureException("User is trying to sign into an account which is not confirmed: "+email)
            }
            setSigningIn(false);
            setHasError(true);
            setErrorText("User is not confirmed.");
        }
        else {
            Sentry.captureException("User encountered error trying to sign in: "+error)
            setSigningIn(false);
            setHasError(true);
            setErrorText("An error occurred. Please try again.");
        }
    }
  };
  return (
    <>
      <NextSeo
        title="Dvele Design Studio | Log in to Your Account"
        description={`Log in or Sign up to save your customized dream module home builds created with Dvele's Design Studio.`}
      />
      {awaitingCode && (
        <>
          <div className="absolute z-10 w-full h-full bg-black bg-opacity-50 z-50 justify-center items-center flex">
            <div className="m-auto w-auto h-2/3 bg-neutral-800 rounded-2xl flex flex-col justify-center items-center sm:w-5/6 md:w-1/2 px-8">
              <FontAwesomeIcon
                icon={faEnvelope}
                className="text-5xl text-white"
              />
              <h3 className="font-medium leading-tight mt-2 text-white bold">
                We have sent a reset code to your email address
              </h3>
              <div className="w-full flex flex-col justify-center items-center">
                <label className="text-white text-xl mt-4 font-extralight opacity-75">
                  Code
                </label>
                <input
                  className="w-2/6 h-10 rounded-md border-2 border-gray-400 mt-2 text-black"
                  type="text"
                  name="code"
                  id="code"
                  onChange={onChange}
                />
                <label className="text-white text-xl mt-4 font-extralight opacity-75">
                  New Password
                </label>
                <input
                  className="w-3/6 h-10 rounded-md border-2 border-gray-400 mt-2 text-black"
                  type="password"
                  name="password"
                  id="newPassword"
                  onChange={onChange}
                />
                <label className="text-white text-xl mt-4 font-extralight opacity-75">
                  Confirm New Password
                </label>
                <input
                  className="w-3/6 h-10 rounded-md border-2 border-gray-400 mt-2 text-black"
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  onChange={onChange}
                />
                {hasError && (
                  <p className="text-red-500 text-sm mt-2">{errorText}</p>
                )}
                <button
                  onClick={() => {
                    console.log("CONFIRM RESET");
                    confirmReset();
                  }}
                  className="justify-center w-2/5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-white focus:shadow-outline mt-4"
                  type="button"
                >
                  {signingIn ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    "Reset Password"
                  )}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="flex studio-main justify-center overflow-hidden h-screen w-screen pt-20 pb-5">
        {forgotPass ? (
          <div className="flex w-5/6 items-center sm:w-4/6 md:w-3/6 lg:w-2/6">
            <div className="bg-[url('/waves.svg')] h-full bg-center w-full bg-cover bg-no-repeat justify-center">
              <div className="flex flex-col items-center h-full w-full">
                <div className="relative w-full">
                  <div className="absolute left-0 ml-4">
                    <div
                      className="px-4 py-4 cursor-pointer"
                      onClick={() => {
                        setHasError(false);
                        setForgotPass(false);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faChevronLeft}
                        className="text-white text-2xl"
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-24">
                  <h1>Reset Password</h1>
                  <form className="pt-6 mt-2 w-full px-6 md:px-8 lg:px-12">
                    <div className="mb-4">
                      <label className="form-label inline-block mb-2 text-white text-xl">
                        Email
                      </label>
                      <input
                        onChange={onChange}
                        type="text"
                        name="email"
                        id="email"
                        className="bg-white border border-white text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        required
                      ></input>
                    </div>
                    <div className="mb-6">
                      <div className="flex justify-center">
                        {hasError && (
                          <p className="text-red-500 text-sm mt-2">
                            {errorText}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="flex justify-center items-center mt-20">
                      <button
                        onClick={reset}
                        className="justify-center w-2/5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-white focus:shadow-outline mt-4"
                        type="button"
                      >
                        Reset
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex w-5/6 items-center sm:w-4/6 md:w-3/6 lg:w-2/6">
            <div className="bg-[url('/waves.svg')] h-full bg-center w-full bg-no-repeat bg-cover justify-center">
              <div className="flex flex-col items-center h-full w-full">
                <div className="mt-24">
                  <h1>Log in</h1>
                </div>
                <form
                  className="pt-6 mt-2 w-full px-6 md:px-8 lg:px-20"
                  onSubmit={(e) => {
                    e.preventDefault();
                    login();
                  }}
                >
                  <div className="mb-4">
                    <label className="form-label inline-block mb-2 text-white text-xl font-extralight opacity-75">
                      Email
                    </label>
                    <input
                      onChange={onChange}
                      type="text"
                      name="email"
                      id="email"
                      className="bg-white border border-white text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                    ></input>
                  </div>
                  <div className="mb-6">
                    <label className="form-label inline-block mb-2 text-white text-xl font-extralight opacity-75">
                      Password
                    </label>
                    <input
                      onChange={onChange}
                      name="password"
                      type="password"
                      id="password"
                      className="bg-white border border-white text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                    ></input>
                    <div className="flex flex-col justify-between">
                      <a
                        className="flex font-bold text-sm text-blue-500 hover:text-blue-800 cursor-pointer"
                        onClick={() => {
                          setForgotPass(true);
                        }}
                      >
                        Forgot Password?
                      </a>
                      {hasError && (
                        <p className="text-red-500 text-sm mt-2">{errorText}</p>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-center items-center">
                    <button
                      //onClick={login}
                      className="justify-center w-2/5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-white focus:shadow-outline mt-4"
                      type="submit"
                    >
                      {signingIn ? (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      ) : (
                        "Log in"
                      )}
                    </button>
                  </div>
                  <div className="flex justify-center items-center mt-2">
                    <p className="text-white">or</p>
                  </div>
                  <div className="flex justify-center m-auto mt-4">
                    <button
                      className="bg-white rounded-md px-8 py-2 mr-2"
                      onClick={() => loginWithApple()}
                    >
                      <FontAwesomeIcon
                        className="text-black text-2xl"
                        icon={faApple}
                      />
                    </button>
                    <button
                      className="bg-white rounded-md px-8 py-2 ml-2"
                      onClick={() => loginWithGoogle()}
                    >
                      <FontAwesomeIcon
                        className="text-black text-1xl"
                        icon={faGoogle}
                      />
                    </button>
                  </div>
                  <div className="flex mt-5 text-xl justify-center items-center">
                    Don&apos;t have an account?&nbsp;
                    <a
                      onClick={() => {
                        if (Router.query && Router.query?.from) {
                            Router.push({
                                pathname: '/register',
                                query: { from: Router.query.from }
                              })
                        }
                        else {
                        Router.push("/register");
                        }
                      }}
                      className="justify-center text-blue-500 hover:text-blue-800"
                      href="#"
                    >
                      Sign up here
                    </a>
                  </div>
                </form>
              </div>
              <form className="pt-6 mt-2 w-full px-6 md:px-8 lg:px-12">
                <div className="mb-4">
                  <label className="form-label inline-block mb-2 text-white text-xl font-extralight opacity-75">
                    Email
                  </label>
                  <input
                    onChange={onChange}
                    type="text"
                    name="email"
                    id="email"
                    className="bg-white border border-white text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  ></input>
                </div>
                <div className="mb-6">
                  <label className="form-label inline-block mb-2 text-white text-xl font-extralight opacity-75">
                    Password
                  </label>
                  <input
                    onChange={onChange}
                    name="password"
                    type="password"
                    id="password"
                    className="bg-white border border-white text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  ></input>
                  <div className="flex flex-col justify-between">
                    <a
                      className="flex font-bold text-sm text-blue-500 hover:text-blue-800 cursor-pointer"
                      onClick={() => {
                        setForgotPass(true);
                      }}
                    >
                      Forgot Password?
                    </a>
                    {hasError && (
                      <p className="text-red-500 text-sm mt-2">{errorText}</p>
                    )}
                  </div>
                </div>
                <div className="flex justify-center items-center">
                  <button
                    onClick={login}
                    className="justify-center w-2/5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-white focus:shadow-outline mt-4"
                    type="button"
                  >
                    {signingIn ? (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                      "Log in"
                    )}
                  </button>
                </div>
                <div className="flex justify-center items-center mt-2">
                  <p className="text-white">or</p>
                </div>
                <div className="flex justify-center m-auto mt-4">
                  <button
                    className="bg-white rounded-md px-8 py-2 mr-2"
                    onClick={() => loginWithApple()}
                  >
                    <FontAwesomeIcon
                      className="text-black text-2xl"
                      icon={faApple}
                    />
                  </button>
                  <button
                    className="bg-white rounded-md px-8 py-2 ml-2"
                    onClick={() => loginWithGoogle()}
                  >
                    <FontAwesomeIcon
                      className="text-black text-1xl"
                      icon={faGoogle}
                    />
                  </button>
                </div>
                <div className="flex mt-5 justify-center items-center">
                  Don&apos;t have an account?&nbsp;
                  <a
                    onClick={() => {
                      Router.push("/register");
                    }}
                    className="justify-center text-blue-500 hover:text-blue-800"
                    href="#"
                  >
                    Register
                  </a>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
export default Login;
